.it-section {
  .it-section__content {
    &.slides {
      margin-bottom: 40px;
      .swiper-container {
        overflow: hidden;
      }
      .it-section__item {
        it-slide {
          .slide-wrapper {
            flex-direction: row;
            align-items: center;
            height: 480px;
            cursor: auto;
            img {
              width: 70%;
              height: 100%;
              overflow: hidden;
              border-radius: 12px;
              object-fit: cover;
              object-position: 93% 50%;
            }
          }
        }
        .text-content {
          position: absolute;
          right: 50px;
          width: 30%;
          background-color: #fff;
          color: #313131;
          padding: 32px 30px;
          font-size: 18px;
          overflow: hidden;
          border-radius: 12px;
          box-shadow: 0 2px 9px 0 rgba($color: #000000, $alpha: .15);
          text-align: left;
          h1 {
            color: #313131;
            font-size: 28px;
          }
          p {
            margin-bottom: 24px;
          }
          it-button {
            button {
              border: solid 1px #313131;
              background-color: #313131;
              height: 40px;
              border-radius: 20px;
              width: max-content;
              width: -moz-max-content;
              padding: 8px 32px;
              span {
                font-size: 15px;
              }
            }
          }
        }
        .swiper-button-next,.swiper-button-prev {
          display: none;
        }
      }
    }
    &.product-list {
      padding-bottom: 40px;
      .it-section__item {
        border-radius: 8px;
      }
    }
    &.category-list {
      margin-bottom: 40px;
    }
    &.newsletter {
      background: url('../assets/images/newsletter-bg.jpg') left no-repeat;
      background-size: cover;
      height: 400px;
      border-radius: 20px;
      overflow: hidden;
      text-align: right;
      padding: 0 40px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      h1 {
        width: 100%;
        max-width: 400px;
        color: #fff;
        text-align: left;
      }
      .it-section__item {
        width: 100%;
        max-width: 400px;
      }
    }
  }
}


.product-details {
  .product-details__container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;

    .it-section {
      margin: 25px 0;
    }
  }
}

@media (max-width: 992px) {
  .it-section {
    .it-section__content {
      &.slides {
        min-width: calc(100% + 40px);
        margin-left: -20px;
        border-radius: 0;
        .it-section__item {
          it-slide {
            .slide-wrapper {
              flex-direction: row;
              align-items: center;
              height: 480px;
              img {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 0;
                object-fit: cover;
                object-position: 93% 50%;
              }
            }
          }
          .text-content {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            width: 100%;
            background-color: transparent;
            overflow: hidden;
            border-radius: 0;
            box-shadow: none;
            text-align: center;
          }
          .swiper-button-next,.swiper-button-prev {
            display: none;
          }
        }
      }
      &.newsletter {
        background: url('../assets/images/newsletter-bg-mobile.jpg') center no-repeat;
        background-size: cover;
        height: 400px;
        min-width: calc(100% + 40px);
        margin-left: -20px;
        border-radius: 0;
        overflow: hidden;
        text-align: right;
        padding: 20px;
        justify-content: flex-start;
        h1 {
          width: 100%;
          max-width: 100%;
        }
        .it-section__item {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
