@font-face {
  font-family:'ns-icons';
  src:url('../assets/icons/ns-icons.ttf');
  src:url('../assets/icons/ns-icons.eot?#iefix') format('embedded-opentype'),
  url('../assets/icons/ns-icons.woff') format('woff'),
  url('../assets/icons/ns-icons.ttf') format('truetype'),
  url('../assets/icons/ns-icons.svg#icomoon') format('svg');
}

// @import "variables";

.ns-icon {
  font-family: ns-icons, sans-serif !important;

  &.cookies {
    &:before {
      content: '\e911';
    }
  }
  &.date {
    &:before {
      content: '\e912';
    }
  }
  &.delete {
    &:before {
      content: '\e913';
    }
  }
  &.login {
    &:before {
      content: '\e914';
    }
  }
  &.cart {
    &:before {
      content: '\e90a';
    }
  }
  &.categories {
    &:before {
      content: '\e90b';
    }
  }
  &.compare-square {
    &:before {
      content: '\e90c';
    }
  }
  &.hours {
    &:before {
      content: '\e905';
    }
  }

  //Menu
  &.menu-close {
    &:before {
      content: '\e915';
    }
  }
  &.menu {
    &:before {
      content: '\e916';
    }
  }
  &.minus {
    &:before {
      content: '\e917';
    }
  }
  &.plus {
    &:before {
      content: '\e918';
    }
  }
  &.protection {
    &:before {
      content: '\e919';
    }
  }
  &.search {
    &:before {
      content: '\e91a';
    }
  }
  &.warning {
    &:before {
      content: '\e91b';
    }
  }

  //Arrows
  &.arrow-left {
    &::before {
      content: '\e907';
    }
  }
  &.arrow-left-double {
    &::before {
      content: '\e906';
    }
  }
  &.arrow-right {
    &::before {
      content: '\e909';
    }
  }
  &.arrow-right-double {
    &::before {
      content: '\e908';
    }
  }

  //Contact
  &.contact-address {
    &:before {
      content: '\e90e';
    }
  }
  &.contact-email {
    &:before {
      content: '\e90f';
    }
  }
  &.contact-phone {
    &:before {
      content: '\e910';
    }
  }

  //Social Media
  &.facebook {
    &:before {
      content: '\e900';
    }
  }
  &.instagram {
    &:before {
      content: '\e901';
    }
  }

  &.address {
    &::before {
      content: '\e91c'
    }
  }

  &.history {
    &::before {
      content: '\e91d'
    }
  }

}
