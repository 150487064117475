@import 'libs/infoteam-ui/src/sass/variables';
@import 'libs/infoteam-ui/src/sass/global';
@import 'libs/infoteam-ui/src/sass/typography';
@import 'libs/infoteam-ui/src/sass/grid';
@import 'libs/infoteam-ui/src/sass/fonts';
@import 'libs/infoteam-ui/src/sass/icons';
@import 'libs/infoteam-ui/src/sass/dropdown';
@import 'libs/infoteam-ui/src/sass/carousel';
@import 'libs/infoteam-ui/src/sass/buttons';
@import 'libs/infoteam-ui/src/sass/inputs';
@import 'libs/infoteam-ui/src/sass/checkbox';
@import 'libs/infoteam-ui/src/sass/select';
@import 'libs/infoteam-ui/src/sass/loading';
@import 'libs/infoteam-ui/src/sass/modal';
@import 'libs/infoteam-ui/src/sass/dropdown';
@import 'libs/infoteam-ui/src/sass/radio';
@import 'libs/infoteam-ui/src/sass/tooltip';
@import 'libs/infoteam-ui/src/sass/dropdown';
@import 'libs/infoteam-ui/src/sass/tabs';
@import 'libs/infoteam-ui/src/sass/accordion';
@import 'libs/infoteam-ui/src/sass/toast';
@import 'libs/infoteam-ui/src/sass/image';
@import 'libs/infoteam-ui/src/sass/pagination';
@import 'libs/infoteam-ui/src/sass/sections';
@import 'sass/icons';
@import 'styles2.scss';

html {
  overflow: hidden;
}

h1 {
  font-family: Manrope, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  color: #313131;
}

h2 {
  font-family: Manrope, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  color: #313131;
}

h3 {
  font-family: Manrope, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: #313131;
}

h4 {
  font-family: Manrope, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 23px;
  color: #313131;
}

h5 {
  font-family: Manrope, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  color: #313131;
}

p {
  font-family: Rubik, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  &.large {
    font-size: 18px;
    font-weight: 600;
  }
  &.medium {
    font-size: 16px;
  }
  &.small {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}

a {
  font-family: Rubik;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  color: #1073e5;
}

ul {
  margin-block-start: 0;
  padding-inline-start: 0;
}

.d-none {
  display: none;
}

.website-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .ns-container {
    width: 100%;
    max-width: 1680px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 20px;
  }
  > header {
    z-index: 2;
  }
  main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    .ns-footer {
      z-index: 1003;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #c6c6c6;
      background-color: #fff;
      .footer-navigation {
        display: flex;
        padding: 4px 0;
        ul {
          list-style: none;
          margin-block-end: 0;
          li {
            margin-bottom: 6px;
            font-family: Manrope, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            &.title {
              margin-bottom: 20px;
              font-size: 18px;
              line-height: 24px;
              color: #313131;
            }
            &.social-media {
              .ns-icon {
                font-size: 24px;
                cursor: pointer;
                &.instagram {
                  margin-left: 15px;
                }
              }
            }
            &.contact {
              display: flex;
              margin-bottom: 10px;
              .ns-icon {
                font-size: 20px;
                margin-right: 10px;
              }
            }
            img {
              margin-right: 20px;
            }
            a {
              color: #666666;
            }
          }
        }
      }
      .copyright {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: 12px 0;
        border-top: 1px solid #c6c6c6;
        color: #808080;
        span {
          font-family: Rubik, sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          margin-right: 3px;
          &:last-of-type {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

it-button {
  width: 100%;
  max-width: 180px;
  .it-button {
    height: 40px;
    width: 100%;
    padding: 8px;
    border-radius: 20px;
    &.primary {
      border: none;
      background-color: #1073e5;
      color: #fff;
      &:disabled {
        background-color: #1073e5;
      }
    }
    &.outline {
      border: 1px solid #313131;
      span {
        font-weight: 400;
        color: #313131;
      }
    }
    span {
      font-family: Rubik, sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &.compare {
    position: fixed;
    right: 15px;
    bottom: 15px;
    max-width: 230px;
    z-index: 1002;
    .it-button {
      height: 48px;
      padding: 10px 20px;
      border-radius: 24px;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
      &::before {
        font-family: ns-icons, sans-serif;
        content: '\e90c';
        font-size: 30px;
      }
      &:hover {
        border: none;
      }
      &.primary {
        background-color: #313131;
        color: #fff;
      }
    }
  }
}
.it-button {
  height: 40px;
  width: 100%;
  padding: 8px;
  border-radius: 20px;
  &.primary {
    border: none;
    background-color: #1073e5;
    &:disabled {
      background-color: #1073e5;
    }
  }
  &.outline {
    border: 1px solid #313131;
    span {
      font-weight: 400;
      color: #313131;
    }
  }
  span {
    font-family: Rubik, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }
}

it-tabs {
  .it-tabs {
    width: 100%;
    height: 48px;
    display: flex;
    border-bottom: 2px solid $gray22;
    &.sticky {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 12;
    }
  }

  .it-tabs__nav {
    ul {
      li {
        background: transparent;
        font-size: 17px;
        font-family: Manrope, sans-serif;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        margin: 8px 8px 2px 40px;
        a {
          color: $gray60;
          transition: color 0.2s ease-in-out;
        }
        &.active {
          background: transparent;
          border-bottom: 2px solid $gray80;
          a {
            color: $gray80;
          }
        }
      }
    }
  }
}

it-accordion {
  .it-accordion__header {
    box-shadow: none !important;
  }
  .it-accordion__body {
    cursor: auto;
  }
}

.it-radio {
  input {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    outline: none;
    &::before {
      width: 18px;
      height: 18px;
    }
    &:checked {
      &::after {
        content: '';
        top: 4px;
        left: 4px;
        padding: 0;
        color: #1073e5;
        background-color: #1073e5;
      }
    }
  }
  label {
    .it-radio__label {
      font-family: Rubik, sans-serif;
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      color: #313131;
    }
  }
}

.it-input__wrapper {
  .it-input {
    input {
      border: 1px solid #dadada;
      padding: 6px 12px;
      font-family: Rubik, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      &:focus {
        border: 1px solid #313131;
        box-shadow: none;
        outline: none;
      }
      &::placeholder {
        color: #797979;
      }
    }
    .it-input__label {
      span {
        font-family: Rubik, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 4px;
        color: #797979;
      }
    }
  }
  .it-input__error-box {
    .it-input__error {
      &.single {
        span {
          font-family: Rubik, sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          color: #e72717;
        }
      }
    }
  }
}

.it-tooltip {
  font-family: Rubik, sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 8px;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #c1c1c1;
  color: #797979;
  background-color: #ececec;
}

.it-select-overlay-container {
  .it-select__list-container {
    .it-select__options-container {
      .option {
        &.checked-dark {
          background-color: #1073e5;
        }
      }
      li {
        .option-text {
          font-family: Rubik, sans-serif;
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }
}

.it-modal {
  &.filter {
    .it-modal__header {
      .it-modal__close {
        font-size: 26px;
        &::before {
          position: initial;
        }
      }
    }
    .it-modal__footer {
      justify-content: space-between;
      padding: 12px 35px;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
      button {
        height: 40px;
        padding: 8px 25px;
        border-radius: 20px;
        &.white {
          border: 1px solid #313131;
          background: #fff;
          span {
            font-weight: 400;
            color: #313131;
          }
        }
        &.primary {
          border: none;
          background-color: #1073e5;
          span {
            font-weight: 500;
            color: #fff;
          }
        }
        span {
          font-family: Rubik, sans-serif;
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }
}

.address-modal {
  .it-modal__footer {
    justify-content: end;
    padding: 12px 20px;
  }
}

.it-loading {
  top: 4px;
  background-color: #fff;
}

.ns-navigation {
  z-index: 1003;
  ul {
    list-style: none;
  }
  .orders-info__wrapper {
    padding: 6px 0;
    font-family: Roboto, sans-serif;
    color: white;
    background-color: #000;
    .orders-info__container {
      max-width: 1680px;
      margin: 0 auto;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      p {
        display: flex;
        align-items: center;
        margin: 0;
      }
      .separator {
        margin: 0 8px;
        color: #797979;
      }
      .ns-icon {
        margin: 0 8px 0 20px;
        font-size: 20px;
      }
      .contact-info {
        display: flex;
        align-items: center;
        a {
          display: flex;
          align-items: center;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .ns-navigation__wrapper {
    color: #313131;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    .ns-navigation__container {
      position: relative;
      height: 68px;
      max-width: 1680px;
      margin: 0 auto;
      display: flex;
      padding: 0 20px;
      .ns-logo {
        width: 220px;
        margin: 14px 0 14px 0;
        background: url('assets/images/navi-logo.png') no-repeat center;
        background-size: contain;
        cursor: pointer;
      }
      .ns-navigation__items {
        display: flex;
        margin: 22px auto;
        margin-block-end: 0;
        color: #313131;
        .main-navigation-item {
          position: relative;
          margin-right: 40px;
          font-family: Rubik, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-transform: uppercase;
          cursor: pointer;
          &.active {
            color: #1073e5;
          }
          .it-icon.arrow-down {
            position: absolute;
            right: -20px;
          }
          &.small {
            position: relative;
            .navigation-dropdown__wrapper {
              position: absolute;
              top: 45px;
              width: auto;
              border-radius: 8px;
              box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
              &.show {
                height: auto;
              }
              .navigation-dropdown {
                height: auto;
                display: initial;
                padding: 15px 20px 5px;
                .navigation-dropdown__list {
                  min-width: max-content;
                  padding: 0;
                  .list-item__title {
                    padding-bottom: 10px;
                    font-size: 15px;
                    letter-spacing: 0;
                    cursor: pointer;
                    &:last-of-type {
                      padding-bottom: 0;
                    }
                  }
                }
                .promo-wrapper {
                  display: none;
                  margin: 10px 0 0;
                }
              }
            }
          }
          .navigation-dropdown__wrapper {
            position: fixed;
            top: 100px;
            left: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
            height: 0;
            width: 100%;
            opacity: 0;
            overflow: hidden;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
            background-color: #fff;
            color: #313131;
            transition: opacity 0.3s ease-in-out;
            z-index: 999;
            &.show {
              height: 400px;
              opacity: 100%;
            }
            .navigation-dropdown {
              display: flex;
              max-width: 1680px;
              margin: 0 auto;
              width: 100%;
              padding: 0 20px;
              flex-wrap: wrap;
              flex-direction: column;
              height: 290px;
              align-content: space-between;
              .navigation-dropdown__list {
                min-width: 230px;
                padding: 20px 16px 0 0;
                &.fixed-width {
                  width: 400px;
                }
                .list-item__title {
                  padding-bottom: 15px;
                  font-family: Rubik, sans-serif;
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: 1px;
                  text-transform: none;
                  cursor: auto;
                }
                .list-items {
                  max-height: 285px;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: wrap;
                  padding-inline-start: 0;
                  gap: 0 24px;
                  padding-top: 15px;
                  border-top: 1px solid #d6d6d6;
                  li {
                    padding: 0 0 10px 0;
                    font-family: Rubik, sans-serif;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0;
                    text-transform: none;
                    cursor: pointer;
                  }
                }
              }
              .promo-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 385px;
                margin: 24px 32px;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                color: #313131;
                cursor: pointer;
                padding: 0 20px;
                img {
                  width: 250px;
                }
                p {
                  padding: 10px 0;
                }
              }
            }
          }
        }
      }
      .navigation-actions {
        position: absolute;
        right: 16px;
        display: flex;
        margin-top: 24px;
        .users {
          font-size: 26px;
          margin-right: 16px;
          cursor: pointer;
        }
        .ns-icon {
          font-size: 26px;
          margin-right: 20px;
          color: #313131;
          cursor: pointer;
          &.cart {
            position: relative;
            .notification {
              content: '';
              position: absolute;
              height: 12px;
              width: 12px;
              border: 2px solid #fff;
              border-radius: 50%;
              top: -2px;
              left: 17px;
              background-color: #1073e5;
            }
          }
          &.login {
            margin-right: 0;
          }
        }
        .it-dropdown {
          .it-dropdown__menu {
            padding: 15px 0;
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
            font-family: Rubik, sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: #313131;
            ul {
              li {
                height: initial;
                padding: 0 20px;
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0;
                }
                &:hover {
                  color: #1073e5;
                }
              }
            }
          }
        }
      }
      it-input {
        position: absolute;
        top: 16px;
        right: 0;
        width: auto;
        .it-input__wrapper {
          .it-input {
            &.search {
              margin-right: 20px;
              input {
                &:focus {
                  border: 1px solid #313131;
                  box-shadow: none;
                  outline: none;
                }
              }
              .search-icon {
                color: #313131;
                &::before {
                  font-family: ns-icons, sans-serif;
                  content: '\e91a';
                  font-size: 22px;
                }
              }
              .clearSearchTerm {
                top: 16px;
                right: 16px;
                width: initial;
                height: initial;
                background-color: unset;
                &::before {
                  // font-family: ns-icons, sans-serif;
                  // font-size: 16px;
                  content: '';
                  // color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ns-navigation__mobile {
  display: none;
  justify-content: space-between;
  height: 120px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  ul {
    list-style: none;
  }
  .ns-nav__actions {
    display: flex;
    font-size: 26px;
    .ns-icon {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
      &.cart {
        position: relative;
        .notification {
          content: '';
          position: absolute;
          height: 12px;
          width: 12px;
          border: 2px solid #fff;
          border-radius: 50%;
          top: -2px;
          left: 17px;
          background-color: #1073e5;
        }
      }
    }
  }
  .ns-logo {
    height: 28px;
    width: 175px;
    margin-bottom: 12px;
    background: url('assets/images/navi-logo.png') no-repeat center;
    background-size: contain;
  }
  .ns-icon.search {
    margin: 6px 16px 0;
    &:before {
      font-size: 38px;
    }
  }
  .navigation-wrapper {
    position: fixed;
    top: 120px;
    left: 0;
    display: flex;
    flex: 1 1 auto;
    height: 0;
    width: 100%;
    overflow: auto;
    background-color: #fff;
    transition: height 0.3s ease-in-out;
    z-index: 1004;
    &.top {
      top: 141px;
    }
    &.show {
      height: 100%;
      padding-bottom: 120px;
    }
    ul {
      display: flex;
      flex-direction: column;
      height: fit-content;
      width: 100%;
      padding: 0 16px;
      .add {
        font-size: 18px;
      }
      .nav-item {
        display: flex;
        flex-direction: column;
        font-family: Rubik, sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        &.sub {
          margin: 0;
          .title {
            margin: 0;
            padding: 15px 0;
            color: #313131;
          }
        }
        &.login {
          margin: 25px 0;
          border-top: 1px solid $gray22;
          border-bottom: 1px solid $gray22;
          .title {
            justify-content: initial;
            padding: 16px 0;
          }
        }
        &.categories {
          .nav-sub__items {
            position: initial;
            padding: 16px 0 0 0;
            li {
              .title {
                padding: 0;
                margin-bottom: 18px;
              }
              &:last-of-type {
                .title {
                  margin-bottom: 30px;
                }
              }
            }
            .nav-sub-sub__items {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              height: 0;
              width: 100%;
              overflow: hidden;
              background-color: #fff;
              transition: height 0.3s ease-in-out;
              &.show {
                height: auto;
                padding: 0 20px;
              }
              li {
                font-family: Rubik, sans-serif;
                font-size: 17px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 15px;
                color: #313131;
              }
            }
          }
        }
        .title {
          display: flex;
          justify-content: space-between;
          margin: 9px 0;
          &.active {
            width: fit-content;
            padding-bottom: 2px;
            border-bottom: 2px solid #1073e5;
          }
          &.contact {
            margin: 0 0 15px 0;
            color: #313131;
          }
          &.bold {
            font-weight: 600;
          }
          .arrow-right,
          .arrow-up,
          .arrow-down {
            font-size: 24px;
          }
        }
        .nav-sub__items {
          position: fixed;
          top: 120px;
          right: -1400px;
          padding-bottom: 125px;
          overflow: auto;
          background-color: #fff;
          transition: right 0.3s ease-in-out;
          cursor: pointer;
          &.show {
            height: 100vh;
            right: 0;
          }
          .go-back {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 15px;
            color: #787878;
            .arrow-left {
              font-size: 24px;
              color: #797979;
            }
          }
          .caption {
            font-weight: 600;
            margin-bottom: 10px;
            color: #313131;
          }
          .nav-sub-sub__items {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            height: 0;
            width: 100%;
            overflow: hidden;
            background-color: #fff;
            transition: height 0.3s ease-in-out;
            &.show {
              height: auto;
              padding: 0 20px;
            }
            li {
              font-family: Rubik, sans-serif;
              font-size: 17px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 15px;
              color: #313131;
            }
          }
        }
      }
    }
  }
  it-input {
    position: fixed;
    top: 45px;
    left: 0;
    padding: 16px 16px 0;
    z-index: 999;
    .it-input__wrapper {
      .it-input {
        &.search {
          margin-right: 0;
          input {
            height: 40px;
            padding: 8px 12px;
            border: 1px solid #dadada;
            font-family: Rubik, sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            &:focus {
              border: 1px solid #313131;
              box-shadow: none;
              outline: none;
            }
            &::placeholder {
              color: #797979;
            }
          }
          .search-icon {
            color: #313131;
            &::before {
              font-family: ns-icons, sans-serif;
              content: '\e91a';
              font-size: 22px;
            }
          }
          .clearSearchTerm {
            // top: 11px;
            // right: 40px;
            // color: #313131;
            background-color: unset;
            &::before {
              content: '';
            }
          }
        }
      }
    }
  }
}

.nav-bottom {
  display: none;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-top: 5px;
  color: #797979;
  span {
    margin: 0 8px;
  }
}

.info-text {
  color: #797979;
}

//Product
.ns-container.product-category {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  overflow: hidden;

  .product-category__header {
    flex: 0 0 80px;
    padding-top: 10px;
  }

  .product-category__content {
    flex: 1 1 100%;
    overflow: hidden;

    > .it-col {
      height: 100%;
      overflow: auto;
    }
  }

  .product-category__info {
    h2,
    p {
      margin: 0;
    }
  }
  .sort {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    p {
      margin: 0 10px 0 0;
      line-height: 20px;
      color: #797979;
    }
    .it-select {
      width: 200px;
      .it-select__control-display {
        height: 40px;
        padding: 8px 12px;
        border: 1px solid #dadada;
        color: #313131;
        .it-select__value {
          font-family: Rubik, sans-serif;
          font-size: 15px;
          line-height: 24px;
        }
        .it-select__deselectAll {
          right: 42px;
        }
      }
    }
    // .it-select-overlay-container {
    //   .it-select__list-container {
    //     .it-select__options-container {
    //       li {
    //         .option-text {
    //           font-family: Rubik, sans-serif;
    //           font-size: 15px !important;
    //           line-height: 24px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .sort-container {
    height: 50px;
    border-radius: 8px;
    display: none;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    span {
      font-family: Rubik, sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: #313131;
      cursor: pointer;
      &:first-of-type {
        width: 200px;
      }
    }
    .separator {
      height: 30px;
      border: 1px solid #c6c6c6;
      margin-right: 12px;
    }
    .sort-option {
      display: flex;
      flex-direction: column;
      .selected-sort__option {
        font-weight: 400;
      }
    }
  }
  .product-card {
    height: 440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    padding: 16px 15px;
    .product-image {
      position: relative;
      display: flex;
      justify-content: center;
      img {
        position: relative;
        width: 200px;
        margin: 0 auto 24px;
        z-index: -1;
      }
      .product {
        position: absolute;
        left: 0;
        width: auto;
        padding: 5px 10px;
        border-radius: 12px;
        font-family: Rubik, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
        &.top {
          top: 30px;
        }
        &.discount {
          background-color: #e21800;
        }
        &.new {
          background-color: #1073e5;
        }
      }
    }
    .product-title {
      font-family: Manrope, sans-serif;
      font-size: 17px;
      font-weight: 400;
      margin-bottom: 4px;
      color: #313131;
    }
    .price {
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 4px;
      color: #1073e5;
      &.discount {
        color: #e21800;
      }
    }
    .old-price {
      font-family: Manrope, sans-serif;
      font-size: 15px;
      font-weight: 400;
      text-decoration: line-through;
      color: #7e7e7e;
    }
    .product-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      .compare {
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-family: Rubik, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
        cursor: pointer;
        &:hover {
          color: #0041ff;
          .compare-square {
            color: #0041ff;
          }
        }
        .compare-square {
          font-size: 30px;
          margin-right: 10px;
          color: #313131;
        }
      }
    }
  }
}

.product-details {
  .product-details__container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
  .image-wrapper {
    width: 100%;
    height: 100%;
    .main-image {
      text-align: center;
      img {
        max-height: 292px;
        max-width: 292px;
      }
    }
    .image-list {
      width: 100%;
      display: flex;
      overflow: auto;
      img {
        max-height: 65px;
        max-width: 65px;
        cursor: pointer;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .price {
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 4px;
    color: #1073e5;
    &.discount {
      color: #e21800;
    }
  }
  .old-price {
    font-family: Manrope, sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-decoration: line-through;
    color: #7e7e7e;
  }
  .small-text {
    font-family: Rubik, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #7e7e7e;
    display: flex;
    align-items: center;
  }
  it-slides {
    display: none;
  }
}

.product-filters {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #c6c6c6;
  .filter-item {
    margin-bottom: 22px;
    .filter-title {
      font-family: Manrope, sans-serif;
      font-size: 15px;
      font-weight: 400;
      color: #313131;
    }
    .it-icon {
      display: none;
    }
  }
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.price {
      it-input {
        input {
          width: 100%;
        }
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
    .it-checkbox {
      height: auto;
      input {
        height: 22px;
        &::before {
          height: 20px;
          width: 20px;
          border-radius: 4px;
          border: 1px solid #c6c6c6;
          font-size: 20px;
        }
        &::after {
          border-radius: 4px;
          border: none;
          color: #1073e5;
        }
      }

      .it-checkbox__label {
        font-family: Rubik, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        padding-top: 0;
        color: #313131;
      }
    }
    .product-number {
      font-family: Rubik, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: #797979;
    }
  }
}

.product-info {
  width: max-content;
  width: -moz-max-content;
  padding: 5px 10px;
  border-radius: 12px;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 8px;
  &.discount {
    background-color: #e21800;
  }
  &.new {
    background-color: #1073e5;
  }
}

.stock-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #42d915;
  margin-right: 10px;
  &.red {
    background-color: $secondary;
  }
  &.yellow {
    background-color: $warning;
  }
}

.compare {
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-family: Rubik, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #313131;
  cursor: pointer;
  &:hover {
    color: #0041ff;
    .compare-square {
      color: #0041ff;
    }
  }
  .compare-square {
    font-size: 30px;
    margin-right: 10px;
    color: #313131;
  }
}

.product-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  .product-card {
    height: 338px;
    width: 286px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    padding: 16px 15px;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
    .product-image {
      position: relative;
      display: flex;
      justify-content: center;
      img {
        position: relative;
        width: 200px;
        margin: 0 auto 24px;
        z-index: -1;
      }
      .product {
        position: absolute;
        left: 0;
        width: auto;
        padding: 5px 10px;
        border-radius: 12px;
        font-family: Rubik, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
        &.top {
          top: 30px;
        }
        &.discount {
          background-color: #e21800;
        }
        &.new {
          background-color: #1073e5;
        }
      }
    }
    .product-title {
      font-family: Manrope, sans-serif;
      font-size: 17px;
      font-weight: 400;
      margin-bottom: 4px;
      color: #313131;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price {
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 4px;
      color: #1073e5;
      &.discount {
        color: #e21800;
      }
    }
    .old-price {
      font-family: Manrope, sans-serif;
      font-size: 15px;
      font-weight: 400;
      text-decoration: line-through;
      color: #7e7e7e;
    }
    .product-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      .compare {
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-family: Rubik, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
        cursor: pointer;
        &:hover {
          color: #0041ff;
          .compare-square {
            color: #0041ff;
          }
        }
        .compare-square {
          font-size: 30px;
          margin-right: 10px;
          color: #313131;
        }
      }
    }
  }
}

.product-properties {
  border: 1px solid $gray22;
  .product-property {
    border-bottom: 1px solid $gray22;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    span {
      width: 40%;
      word-break: break-word;
      &:last-child {
        padding-left: 5px;
        width: 60%;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

// Auth

.login,
.registration,
.password-change,
.password-change-request {
  h1 {
    margin: 30px 0;
  }
  .form-data {
    max-width: 440px;
  }
}

.password-change {
  p.light {
    color: #797979;
  }
}

.registration {
  width: 100%;
  margin-bottom: 60px !important;
  .done {
    margin-top: 30px;
    text-align: center;
    &::before {
      font-size: 42px;
      color: #27b33d;
    }
  }
  .terms-of-conditions {
    flex-direction: row !important;
    align-items: center;
  }
  .newsletter {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    margin-top: 30px;
    background: url('assets/images/newsletter-bg.jpg') no-repeat center;
    background-size: cover;
    .newsletter-info__container {
      position: absolute;
      right: 200px;
      h1 {
        color: #fff;
        margin: 0;
        &:first-of-type {
          font-weight: 200;
        }
      }
      .it-button {
        margin-top: 30px;
        border: 1px solid #fff;
        &.outline {
          background-color: transparent;
          &:hover {
            .it-button__text,
            span,
            &:before {
              color: #fff !important;
            }
          }
          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.profile-page {
  .profile-navigation {
    li {
      display: flex;
      align-items: center;
      font-family: Rubik, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 22px;
      padding: 10px 20px;
      margin-bottom: 10px;
      color: #313131;
      cursor: pointer;
      .ns-icon {
        font-size: 28px;
        margin-right: 10px;
      }
      &.active,
      &:hover {
        color: #fff;
        background-color: #1073e5;
      }
    }
  }
  .delete-profile {
    padding: 20px 0 0 0;
    border-top: 1px solid #c6c6c6;
    p {
      display: flex;
      align-items: center;
      cursor: pointer;
      .ns-icon {
        font-size: 20px;
        margin-right: 12px;
        color: #313131;
      }
    }
  }
  .order-wrapper {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    &.background-none {
      padding: 0;
      box-shadow: none;
    }
    &.price-container {
      .amount {
        font-family: Manrope, sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        color: #797979;
      }
      .price {
        font-family: Manrope, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
        &.discount {
          color: #e72717;
        }
      }
    }
    .order-info__wrapper {
      flex-direction: row;
    }
    .order-info {
      padding: 20px 0;
      border-top: 1px solid #c6c6c6;
    }
    .order-status {
      font-family: Rubik, sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      padding: 5px 15px;
      border-radius: 15px;
      text-transform: capitalize;
      color: #fff;
      background-color: #70abef;
      &.processing {
        background-color: #1073e5;
      }
      &.on-delivery {
        background-color: #ffa931;
      }
      &.delivered {
        background-color: #27b33d;
      }
      &.canceled {
        background-color: #e72717;
      }
    }
    .product-wrapper {
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 10px 12px;
      border-radius: 8px;
      border: 1px solid #c6c6c6;
      &.large {
        height: 150px;
        align-items: flex-start;
        position: relative;
        padding: 15px;
        img {
          height: 120px;
          margin-right: 24px;
        }
        .price {
          position: absolute;
          right: 15px;
          bottom: 15px;
          span {
            font-family: Manrope, sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #313131;
          }
        }
      }
      img {
        height: 60px;
        margin-right: 8px;
      }
      .product-name {
        font-family: Manrope, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #313131;
      }
    }
  }
  .address-container {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    .header {
      margin-bottom: 20px;
      .title {
        font-family: Rubik, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: #313131;
      }
      .it-icon {
        font-size: 20px;
      }
    }
    .body {
      p {
        margin-bottom: 0;
      }
    }
    .footer {
      flex-direction: row;
      justify-content: end;
      a {
        &:first-of-type {
          margin-right: 15px;
          color: #797979;
        }
      }
    }
  }
  .profile-navigation__responsive {
    display: none;
    .it-dropdown {
      display: flex;
      align-items: center;
      padding: 12px 25px;
      margin-bottom: 4px;
      border-radius: 24px;
      font-family: Rubik, sans-serif;
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      color: #fff;
      background: #1073e5;
      .ns-icon {
        font-size: 28px;
        margin-right: 16px;
      }
      .it-icon {
        position: absolute;
        right: 25px;
        font-size: 24px;
      }
      &.show {
        border-radius: 24px 24px 0 0;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
        color: #313131;
        background-color: #fff;
      }
      .it-dropdown__menu {
        width: 100%;
        top: 48px;
        padding: 0;
        border-radius: 0 0 24px 24px;
        box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.15);
        li {
          height: 46px;
          padding: 12px 25px;
          font-family: Rubik, sans-serif;
          font-size: 17px;
          font-weight: 400;
          line-height: 24px;
          color: #313131;
          &:hover {
            font-weight: 500;
            color: #fff;
            background-color: #1073e5;
            &:last-of-type {
              border-radius: 0 0 24px 24px;
            }
          }
        }
      }
    }
  }
  .order-cancel {
    font-size: 16px;
    color: #797979;
  }
}

.page-card {
  height: 100%;
  padding-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  .image-placeholder {
    height: 175px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    margin-bottom: 20px;
    background: url('assets/images/news-placeholder.svg') no-repeat center;
    background-size: cover;
  }
  img {
    height: 175px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: center;
  }
  .page-info {
    padding: 0 15px;

    h3 {
      letter-spacing: 0;
    }
  }
  .info-text {
    margin-bottom: 20px;
  }
}

ns-filters {
  ul {
    list-style: none;
  }
}

ns-login,
ns-registration,
ns-password-change,
ns-password-change-request,
ns-profile,
ns-order-list,
ns-addresses,
ns-order-details {
  display: flex;
  flex: 1 1 auto;
}

@media (max-width: 1580px) {
  .ns-navigation {
    .ns-navigation__wrapper {
      .ns-navigation__container {
        .ns-navigation__items {
          .main-navigation-item {
            .navigation-dropdown__wrapper {
              .navigation-dropdown {
                .navigation-dropdown__list {
                  min-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1380px) {
  .ns-navigation {
    display: none;
  }
  .ns-navigation__mobile {
    display: flex;
    padding: 16px 20px;
  }
}

@media (max-width: 992px) {
  ns-footer {
    padding: 0 12px;
  }
  .website-root {
    main {
      .ns-footer {
        .footer-navigation {
          ul {
            &.payment {
              text-align: center;
            }
            li {
              font-size: 16px;
              &.title {
                margin-bottom: 18px;
                text-align: initial;
              }
              &.social-media {
                .ns-icon {
                  font-size: 32px;
                }
              }
              &.cards {
                text-align: center;
              }
            }
          }
        }
        .copyright {
          flex-direction: column;
          justify-content: flex-start;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .product-category {
    .product-category-filters__wrapper {
      display: none;
    }
    .sort {
      display: none;
    }
    .sort-container {
      display: flex;
    }
  }
  .registration {
    .newsletter {
      .newsletter-info__container {
        right: initial;
      }
    }
  }
  .profile-page {
    .profile-navigation {
      display: none;
    }
    .profile-navigation__responsive {
      display: flex;
    }
    .order-wrapper {
      &.price-container {
        padding: 0;
        box-shadow: none;
      }
      .order-info {
        padding: 0;
        &:last-of-type {
          padding-top: 20px;
        }
        .border {
          &.top {
            border-top: 1px solid #c6c6c6;
            padding-top: 20px;
          }
          &.bottom {
            border-bottom: 1px solid #c6c6c6;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .website-root {
    main {
      .ns-footer {
        margin-top: 20px;
      }
    }
  }
  .nav-bottom {
    position: absolute;
    bottom: 0;
    height: 0;
    opacity: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 1003;
    transition: height 0.2s linear, opacity 0.1s linear;
    &.show {
      opacity: 100%;
      height: 72px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .ns-icon {
        font-size: 30px;
        margin-bottom: 4px;
        color: #313131;
      }
      span {
        font-family: Rubik, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
      }
    }
  }
  .registration {
    margin-bottom: 0 !important;
    &.success-message {
      padding: 0 !important;
    }
    .it-button.outline span {
      font-size: 17px;
    }
    .newsletter {
      justify-content: initial;
      align-items: initial;
      background: url('assets/images/newsletter-bg-mobile.jpg') no-repeat center;
      background-size: cover;
      border-radius: 0;
      .newsletter-info__container {
        top: 20px;
        left: 20px;
        h1 {
          font-size: 20px;
        }
      }
    }
  }
  .password-change {
    h1 {
      font-size: 22px;
      line-height: 33px;
    }
    p.large {
      font-size: 17px;
    }
    it-button {
      max-width: 100%;
      .it-button {
        span {
          font-size: 17px;
        }
      }
    }
  }
  .product-filters {
    &.in-modal {
      &:last-of-type {
        border-bottom: 1px solid #c6c6c6;
      }
      .filter-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        .filter-title {
          font-size: 17px;
        }
        .it-icon {
          display: block;
          font-size: 24px;
        }
      }
      .filter-options {
        display: none;
        margin-top: 18px;
        &.show {
          display: block;
        }
      }

      .filter {
        &.price {
          margin-top: 20px;
        }
        .it-checkbox {
          height: 24px;
          input {
            height: 24px;
            &::before {
              height: 22px;
              width: 22px;
            }
          }
          .it-checkbox__label {
            font-size: 17px;
          }
        }
        .product-number {
          font-size: 17px;
        }
      }
    }
  }
  .profile-page {
    .order-wrapper {
      position: relative;
      &.background-none {
        .order-status {
          right: 0;
        }
      }
      .orders-info__wrapper {
        flex-wrap: wrap;
        position: relative;
        div {
          &:first-of-type {
            width: 100%;
          }
        }
      }
      .order-status {
        position: absolute;
        top: 15px;
        right: 15px;
      }
      .product-wrapper {
        margin: 0 0 10px 0;
        .product-name {
          font-size: 14px;
        }
      }
    }
  }
  .page-card {
    .info-text {
      font-size: 16px;
    }
  }
  .product-details {
    it-slides {
      display: block;
      .product-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
        padding: 16px 15px;
        margin: 0px 4px;
        &:last-child {
          margin-right: 0;
        }
        .product-image {
          position: relative;
          display: flex;
          justify-content: center;
          img {
            position: relative;
            width: 200px;
            margin: 0 auto 24px;
            z-index: -1;
          }
          .product {
            position: absolute;
            left: 0;
            width: auto;
            padding: 5px 10px;
            border-radius: 12px;
            font-family: Rubik, sans-serif;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #fff;
            &.top {
              top: 30px;
            }
            &.discount {
              background-color: #e21800;
            }
            &.new {
              background-color: #1073e5;
            }
          }
        }
        .product-title {
          font-family: Manrope, sans-serif;
          font-size: 17px;
          font-weight: 400;
          margin-bottom: 4px;
          color: #313131;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price {
          font-family: Manrope, sans-serif;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 4px;
          color: #1073e5;
          &.discount {
            color: #e21800;
          }
        }
        .old-price {
          font-family: Manrope, sans-serif;
          font-size: 15px;
          font-weight: 400;
          text-decoration: line-through;
          color: #7e7e7e;
        }
        .product-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          .compare {
            display: flex;
            align-items: center;
            margin-right: 8px;
            font-family: Rubik, sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: #313131;
            cursor: pointer;
            &:hover {
              color: #0041ff;
              .compare-square {
                color: #0041ff;
              }
            }
            .compare-square {
              font-size: 30px;
              margin-right: 10px;
              color: #313131;
            }
          }
        }
      }
    }
    it-button {
      max-width: 100%;
    }
    it-tabs {
      .it-tabs {
        .it-tabs__nav {
          ul {
            padding: 0;
            overflow-x: auto;
            overflow-y: hidden;
            li {
              margin: 0;
              min-width: 115px;
            }
          }
        }
      }
    }
    .image-wrapper {
      display: none;
    }
    .stock_status {
      display: inline-block;
      text-align: center;
      margin-bottom: 10px !important;
    }
    .compare {
      justify-content: center;
    }
    .product-cards-wrapper {
      display: none;
    }
  }
}

.navishop-additional-categories {
  margin-bottom: 50px;

  .it-section__content > div {
    position: relative;

    img {
      display: block;
      width: 100%;
      z-index: 0;
      border-radius: 8px;
      overflow: hidden;
    }

    h4 {
      position: absolute;
      top: 16px;
      left: 16px;
      font-size: 20px;
      z-index: 1;
      color: #fff;
      text-shadow: 1px 1px rgb(0 0 0 / 80%);
    }
  }
}

.scroll-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 0;
  width: 0;
  border: #fff 1px solid;
  border-radius: 50%;
  background-color: #3876bc;
  color: #fff;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  z-index: 999;

  transition: opacity 0.4s linear;

  .it-icon:before {
    font-size: 24px;
  }

  &.show {
    height: 40px;
    width: 40px;
    opacity: 1;
  }
}
